.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #999;
}

.Home .meetings h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Home .meetings p {
  color: #666;
}

.Home .meetingCard {
  height:220px;
  width: 200px;
  font-size: 0.8em;
  margin: 10px;
  background-color: #f2f2f2;
  border: solid 1px #80808038;
  border-radius: 8px;
  padding: 12px;
  overflow-wrap: break-word;
  //white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.meetingCard:hover {
  background-color: lightyellow;
  transform: scale(1.05);
}

.createFreeMeeting {
  margin: 0px;
  background-color: ;
  border-radius: 8px;
  padding: 0px;
}

.createFreeMeeting:hover {
  background-color: #99ccff;
  transform: scale(1.05);
}

.Home .meetingList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: center; 
}

@media all and (max-width: 700px) {
  .meetingList > .meetingCard {
      flex: 1 1 46%;
  }
}
 
@media all and (max-width: 550px) {
  .meetingList > .meetingCard {
      flex: 1 1 100%;
  }
}
