.about {
  padding: 10px 0;
  text-align: center;
}

.about h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: black;
}

.about p {
  color: black;
}
