.JoinMeeting form {
    margin: 0 auto;
    max-width: 480px;
  }

.JoinMeeting p {
    margin: 0 auto;
    max-width: 480px;
  }

.JoinMeeting .buttonbox {
    text-align: center;
}

.JoinMeeting .buttonbox .bc {
  float: left;
  margin-right:10px;
}              
