.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
  font-size: 25px;
  color: white;
}

.navbar-default .navbar-nav>li>a {
  color: white;
}

.App .navmenu {
  background-color: #0174DF;
}
