.JoinMeetingWithName form {
    margin: 0 auto;
    max-width: 480px;
  }

.JoinMeetingWithName p {
    margin: 0 auto;
    max-width: 480px;
  }

.JoinMeetingWithName .buttonbox {
    text-align: center;
}

.JoinMeetingWithName .buttonbox .bc {
  float: left;
  margin-right:10px;
}              
