.JoinAMeeting form {
    margin: 0 auto;
    max-width: 480px;
  }

.JoinAMeeting p {
    margin: 0 auto;
    max-width: 480px;
  }

.JoinAMeeting .buttonbox {
    text-align: center;
}

.JoinAMeeting .buttonbox .bc {
  float: left;
  margin-right:10px;
}                                                                                                                                       
