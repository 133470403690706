.NewMeeting form {
  padding-bottom: 15px;
}

.NewMeeting form textarea {
  height: 180px;
  font-size: 15px;
}

.fieldbox {
  display: flex;
  justify-content: space-around;
}                                                                                                                                    

@media all and (max-width: 700px) {
    .fieldbox {
        flex-wrap: wrap;
    }
    .fieldbox > .ff {
      flex: 45%;
    }
}

@media all and (max-width: 550px) {
    .fieldbox > .ff {
        flex: 1 1 100%;
    }
}

